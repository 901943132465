<template>
  <div class=""><router-view></router-view></div>
</template>
<script>
export default {
  props: {},
  name: 'Index',
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  components: {},
};
</script>
<style lang="scss" scoped></style>
